import { render, staticRenderFns } from "./groupPop.vue?vue&type=template&id=64b14b15&scoped=true&"
import script from "./groupPop.vue?vue&type=script&lang=js&"
export * from "./groupPop.vue?vue&type=script&lang=js&"
import style0 from "./groupPop.vue?vue&type=style&index=0&id=64b14b15&prod&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "64b14b15",
  null
  
)

export default component.exports