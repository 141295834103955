<template>
  <el-dialog :visible.sync="isShow" @close="" :close-on-click-modal="false"
    custom-class="group-dialog" :modal-append-to-body="false">
    <div class="dialog-group-main">
      <!-- 现金转盘 -->
      <div class="dialog-pane" v-show="activeTab === 0">
        <pddPop ref="pddPops" v-if="$route.query.modal != 'LoginRegistration'" @update:isShow="isShow = $event" />
      </div>
      <!-- LUCKY -->
      <div class="dialog-pane" v-show="activeTab === 1">
        <fiveBessingsFirstPop ref="fiveBessingsFirstPop" />
        <fiveBessingsPop ref="fiveBessingsPop" />
      </div>
      <!-- 红包雨 -->
      <div class="dialog-pane" v-show="activeTab === 2">
        <redEnvelopeRain ref="redEnvelopeRains" />
      </div>
      <!-- 幸运转盘 -->
      <div class="dialog-pane" v-show="activeTab === 3">
        <furyWheel :wheelShow="activeTabWheel" />
      </div>
      <div class="dialog-tab">
        <div class="tab-item" :class="{ active: activeTab === 0 }" @click="setActiveTab(0)">
          <el-badge :is-dot="badgeDots[0]" class="item-dot"><span class="iconfont icon-tianchongxing-"></span></el-badge>
          <span class="tab-title">{{ $t('现金转盘') }}</span>
        </div>
        <div class="tab-item" :class="{ active: activeTab === 1 }" @click="setActiveTab(1)">
          <el-badge :is-dot="badgeDots[1]" class="item-dot"><span class="iconfont icon-tianchongxing-"></span></el-badge>
          <span class="tab-title">{{ $t('LUCKY') }}</span>
        </div> 
        <div class="tab-item" :class="{ active: activeTab === 2 }" @click="setActiveTab(2)">
          <el-badge :is-dot="badgeDots[2]" class="item-dot"><span class="iconfont icon-tianchongxing-"></span></el-badge>
          <span class="tab-title">{{ $t('红包雨') }}</span>
        </div>
        <div class="tab-item" :class="{ active: activeTab === 3 }" @click="setActiveTab(3)">
          <el-badge :is-dot="badgeDots[3]" class="item-dot"><span class="iconfont icon-tianchongxing-"></span></el-badge>
          <span class="tab-title">{{ $t('幸运转盘') }}</span>
        </div>
      </div>
    </div>
    <div class="hide-close" @click="hide">
      <span class="iconfont icon-menu-close"></span>
    </div>
  </el-dialog>
</template>
<script>
import pddPop from "./pdd/pddPop.vue"
import redEnvelopeRain from "./redEnvelopeRain/index.vue"
import furyWheel from "./furyWheel.vue"
import fiveBessingsFirstPop from "./fiveBessings/fiveBessingsFirstPop.vue"
import fiveBessingsPop from "./fiveBessings/fiveBessingsPop.vue"
import { wufu_getData_api } from "@/api/wufu"
export default {
  components: { pddPop, redEnvelopeRain, furyWheel,fiveBessingsFirstPop,fiveBessingsPop },
  data() {
    return {
      isShow: false,
      turntableShow: false,
      activeTab: 0,
      activeTabWheel:false,
      badgeDots: [false, true, true, true] // Default state
    }
  },
  watch: {
    $route: {
      handler(to) {
        if (to.query.dialog == "allGroup") {
          this.show()
        }
        if (to.query.type !== undefined) {
          this.setActiveTab(Number(to.query.type))
        }
      },
      immediate: true
    },
    "$store.state.isLoginStatu": {
      handler() {
        this.getwufuData()
        if (this.$route.path == "/allGroup") return
      }
    },
    "$route.path": function (v) {
      if (v == "/") {
        if (this.$store.state.isLoginStatu) this.getwufuData()
      }
    }
  },
  mounted() {
    this.loadBadgeDots();
  },
  methods: {
    async getwufuData() {
      const { data } = await wufu_getData_api()
      if (data.code == 1) {
        this.$store.commit("fiveBessings/updateFiveBessData", data.data)
        // 没有登录判断有数据显示
        if (!this.$store.state.isLoginStatu) {
          if (data.data.id) {
            this.$refs.fiveBessingsFirstPop.notLoginShow()
          }
        } else {
          // 登录判断次数显示
          if (data.data.num > 0) {
            if (data.data.open_num == 0) {
              this.$refs.fiveBessingsFirstPop.show(data.data)
            } else {
              this.$refs.fiveBessingsPop.show()
            }
          }
        }


      }
    },
    show() {
      this.isShow = true
    },
    hide() {
      this.isShow = false;
      this.$router.push({ query: {} });
    },
    setActiveTab(index) {
      this.activeTab = index;
      this.badgeDots[index] = false; // Remove the dot for the activated tab
      this.saveBadgeDots(); // Save the updated state
      console.log(index,'setActiveTab(index)')
      if(index == 0){
        this.$refs.pddPops.show();
      }
      if (index == 1) {
        this.getwufuData();
      }
      if (index == 2) {
        if (this.$refs.redEnvelopeRains) {
          this.$refs.redEnvelopeRains.showPop();
        } else {
          console.error("redEnvelopeRains is not defined");
        }
      }
      if (index == 3) {
        this.activeTabWheel = true;
      }
    },
    saveBadgeDots() {
      const data = {
        badgeDots: this.badgeDots,
        timestamp: Date.now()
      };
      localStorage.setItem('badgeDots', JSON.stringify(data));
    },
    loadBadgeDots() {
      const data = JSON.parse(localStorage.getItem('badgeDots'));
      if (data) {
        const currentTime = Date.now();
        const timeElapsed = currentTime - data.timestamp;
        const oneDay = 24 * 60 * 60 * 1000; // 24 hours in milliseconds

        if (timeElapsed < oneDay) {
          this.badgeDots = data.badgeDots;
        } else {
          this.resetBadgeDots();
        }
      }
    },
    resetBadgeDots() {
      this.badgeDots = [true, true, true, true];
      this.saveBadgeDots();
    }
  }
}
</script>

<style scoped lang="less">
  .dialog-tab{
    display: flex;
    align-items: center;
    gap: 6px;
    .tab-item{
      background-color: #16202a;
      border-radius: 6px;
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      padding:10px 10px;
      color: #737985;
      cursor: pointer;
      .iconfont{
        font-size: 22px;
      }
      .tab-title{
        font-weight: 600;
        margin-left: 5px;
      }
      &.active{
        background-image: linear-gradient(to right, #a85dfe, #c292f6);
        color: #ffffff;
        .iconfont{
          color: #fcd280;
        }
      }
    }
  }
  .hide-close{
    width: 36px;
    height: 36px;
    border-radius: 50%;
    bottom: 0;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 3px solid #ffffff;
    opacity: .7;
    cursor: pointer;
    .iconfont{
      font-size: 20px;
      color: #ffffff;
    }
  }
  /deep/ .group-dialog{
    width: 520px;
    background: transparent;
    .el-dialog__body{
      padding: 0;
    }
    .el-dialog__header{
      display: none;
    }
  }
  .item-dot{
    line-height: 1;
    display: inline-flex;
  }




</style>
