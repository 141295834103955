<template>
  <div class="el-dialog-wufu" v-if="isShow">
    <div class="content-box">
      <div class="title" :data-text="$t(title)">{{ $t(title) }}</div>
      <div class="content">
        <img src="@/assets/images/wufutishibeijing.png" class="bg-img">
        <img :src="icon" class="cover">
      </div>
      <slot name="button">
        <el-button class="button_fullwidth five-button" size="mini" @click="submit">{{ $t(bthText) }}</el-button>
      </slot>
    </div>
    <div class="close-icon" @click="isShow = false">
      <img :src="require('@/assets/images/five-close-icon.png')" alt="">
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isShow: false,
      bthText: "",
      title: "",
      icon: "",
      callBack: null,
    }
  },
  methods: {
    show({ callBack, bthText, icon, title }) {
      this.icon = icon
      this.title = title
      this.bthText = bthText
      this.isShow = true
      this.callBack = callBack
    },
    async submit() {
      this.callBack && await this.callBack()
      this.isShow = false
    }
  }
}
</script>

<style scoped lang="less">
::v-deep .el-dialog {
  box-shadow: none;
  max-width: calc(100vw - 40px);
  min-width: 300px;
  width: 350px;
  background-color: transparent;
  background-image: url(~@/assets/images/five-tip-pop-bg.png);
  background-size: 100% 100%;
  margin: 0 auto 50px !important;

  .el-dialog__header {
    display: none;
  }

  .el-dialog__body {
    padding: 0;
    color: #FFF;
  }
}

.content-box {
  padding: 80px 26px 36px 26px;
  min-height: 350px;
}

.title {
  text-align: center;
  width: 90%;
  margin: 0 auto;
  font-size: 30px;
  font-weight: bold;
  color: #A6400E;
  position: relative;

  &::before {
    width: 100%;
    content: attr(data-text);
    position: absolute;
    left: 0;
    top: 0;
    z-index: 2;
    background: linear-gradient(12deg, #EA35CE 0%, #FA6EA4 0%, #FDE878 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    transform: translate(-1px, -3px);
  }

  &.title-error {
    color: #000;

    &::before {
      background: linear-gradient(12deg, #E2E2E2 0%, #ABABAB 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
}

.five-button {
  padding: 10px 15px;
  margin-top: 30px;
}

.content {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 140px;
  position: relative;
}

.bg-img {
  position: absolute;
  width: 80%;
  animation: line-run infinite linear 6s;
}

@keyframes line-run {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.cover {
  width: 69px;
  position: relative;
  z-index: 2;
}

.close-icon {
  width: 32px;
  height: 32px;
  cursor: pointer;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: calc(100% + 10px);

  @media (max-width:540px) {
    width: 27px;
    height: 27px;
  }

  img {
    width: 100%;
    height: 100%;
  }
}
</style>