<template>
  <div class="task-item-wapper">
    <div @click="tabItem"><img
        :src="data.is_receive == 1 ? require('@/assets/images/is_receive_box.png') : require('@/assets/images/task-box.png')"
        :class="['task-box-icon', data.is_receive == 1 || !progress ? 'task-box-icon-dis' : '']"></div>
    <div class="task-item-content "
      :class="{ finish_item: data.is_finish == 1 && data.is_receive == 0, receive_item: data.is_finish == 1 && data.is_receive == 1 }"
      @click="tabItem" v-loading="loading">
      <img v-if="rewardAount ==1" class="bi-icon" style="transform: scale(.9);" :src="require('@/assets/images/icon_gift.png')" alt="">
      <img v-else-if="rewardAount ==2" class="bi-icon" :src="require('@/assets/images/vnd.png')">
      <img v-else-if="rewardAount ==3" class="bi-icon" style="transform: scale(.9);" :src="require('@/assets/images/integral.png')">
      <img v-else-if="rewardAount ==4" class="bi-icon" style="transform: scale(.9);" :src="require('@/assets/images/fiveB.png')">
      <img v-else-if="rewardAount ==5" class="bi-icon" style="transform: scale(.9);" :src="require('@/assets/images/free-game.png')">
      <img v-else class="bi-icon" style="transform: scale(.9);" :src="require('@/assets/images/icon_gift.png')">

      <span class="item-num">
        <div class="is_receive_text" style="color: gray;" v-if="data.is_receive == 1">{{ $t("完成") }}</div>
        <div v-else>
          <span v-if="rewardAount ==2">{{ $helper.get("rule").currency.symbol }}</span> 
          <span v-if="rewardAount ==2">{{data.bl_or_amount }}</span>
          <span v-else-if="rewardAount ==3">{{data.integral_amount}}</span>
          <span v-else-if="rewardAount ==4">{{data.w_count}}</span>
          <span v-else-if="rewardAount ==5">{{data.free_game.round}}</span>
          <span v-else>1</span>
          </div>
      </span>
      <!-- <div class="tip" v-if="data.is_receive==1">{{ $t("完成") }}</div> -->
    </div>
    <div class="progress">
      <div class="progress-bg" :style="`width:${progress}%`"></div>
      <div :class="['progress-content', progress >= 50 ? 'progress-content-ac' : '']"></div>
    </div>
    <div class="bottom-num"><img class="bi-icon" :src="require('@/assets/images/flame_animation.gif')" alt="">{{
      data.max_activity }}</div>
  </div>
</template>

<script>
export default {
  props: {
    progress: {
      type: Number,
      default: 0
    },
    data: {
      type: Object
    },
    loading: {
      type: Boolean
    }
  },
  data(){return{
    rewardAount:0
  }},
  mounted(){
    this.checkProperties()
  },
  methods: {
    tabItem() {
      if (this.progress && this.data.is_receive != 1) {
        this.$emit("tabItem")
      }
    },
    checkProperties() {
      const { free_game, w_count, bl_or_amount, integral_amount } = this.data
      // 统计有值的属性数量
      const nonEmptyProperties = [
        free_game,
        w_count,
        bl_or_amount,
        integral_amount
      ].filter(value => value !== undefined && value !== null && value !== ''&& value.length != 0&& value.length != 0&& value != "0"); // 排除 null、undefined 和 空字符串
      console.log(nonEmptyProperties.length,'fafaf');
      
      // 条件判断 1为两个以上奖励、 2为单奖金、3为积分、4为五福次数、5为免费游戏次数
      if (nonEmptyProperties.length >= 2) {
        this.rewardAount = 1;
      } else if (nonEmptyProperties.length == 1) {
        if (nonEmptyProperties[0] == bl_or_amount) {
          this.rewardAount = 2;
        } else if (nonEmptyProperties[0] == integral_amount) {
          this.rewardAount = 3;
        }else if(nonEmptyProperties[0] == w_count){
          this.rewardAount = 4;
        }else if(nonEmptyProperties[0] == free_game){
          this.rewardAount = 5;
        }
      }else{
        this.rewardAount = 6; // 如果不满足上述条件
      }
    }
  }
}
</script>

<style scoped lang="less">
.tip {
  position: absolute;
  transform: rotate(20deg);
  color: #FFF;
  text-shadow: 0 0 10px #000;
  font-weight: bold;
  font-size: 20px;
  top: 0;
  z-index: 3;
  opacity: .6;
}

.is_receive_text {
  color: #fff;
}

.task-item-wapper {
  cursor: pointer;
}

.task-box-icon {
  width: 100%;
  transform: translateX(-5%);
  position: relative;
  z-index: 2;
}

.task-box-icon-dis {
  filter: grayscale(100%);
}

.bi-icon {
  width: 32px;
  height: 32px;
  object-fit: cover;
  margin: 0 4px;
}

.task-item-content {
  width: 100%;
  display: flex;
  align-items: center;
  background-color: #161D37;
  border-radius: 7px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 20%;
  margin-top: -20%;
  padding-bottom: 12px;
  position: relative;
  border: 1px solid transparent;
}

.finish_item {
  border: 1px solid #B1B4BC;
  background-color: #444A5F !important;
}

.receive_item {
  filter: grayscale(100%);
}

.item-num {
  font-size: 18px;
}

.progress {
  height: 3px;
  background-color: #0d162d;
  margin: 33px -8px 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  max-width: 200%;

  @media (max-width:768px) {
    margin: 20px -8px 20px;
  }
}

.progress-content {
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background-color: #0d162d;
}

.progress-content-ac {
  background-color: #CC9DFA;
  box-shadow: 0 0 4px #CC9DFA;
}

.progress-bg {
  position: absolute;
  background-color: #CC9DFA;
  box-shadow: 0 0 4px #CC9DFA;
  width: 50%;
  height: 100%;
  left: 0;
}

.bottom-num {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 21px;
  font-weight: bold;

  @media (max-width:768px) {
    font-size: 16px;
  }
}

.task-item-wapper .el-loading-mask {
  top: -60%;
  border-radius: 10px;
}
</style>