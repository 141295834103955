<template>
  <div :endTime="endTime" :callback="callback" :endText="endText" v-html="content" class="countdown-text">
  </div>
</template>
<script>
  export default {
    data(){
      return {
      content: '',
      }
    },
    props:{
      endTime:{
        type: Number,
        default :''
      },
      endText:{
        type : String,
        default:'已结束'
      },
      callback : {
        type : Function,
        default :''
      }
    },
    mounted () {
     this.countdowm(this.endTime)
    },
    methods: {
      countdowm(timestamp){
      let self = this;
      let timer = setInterval(function(){
        let nowTime = new Date();
        let endTime = new Date(timestamp * 1000);
        let t = endTime.getTime() - nowTime.getTime();
        if(t>0){
          let day = Math.floor(t/86400000);
          let hour=Math.floor((t/3600000)%24);
          let min=Math.floor((t/60000)%60);
          let sec=Math.floor((t/1000)%60);
          hour = hour < 10 ? "0" + hour : hour;
          min = min < 10 ? "0" + min : min;
          sec = sec < 10 ? "0" + sec : sec;
          let format = '';
          if(day > 0){
            format = `${day}<span class="yellow-text">D</span> | ${hour}<span class="yellow-text">H</span> | ${min}<span class="yellow-text">M</span> | ${sec}<span class="yellow-text">S</span>`;
          } 
          if(day <= 0 && hour > 0 ){
            format = `${hour}<span class="yellow-text">H</span> | ${min}<span class="yellow-text">M</span> | ${sec}<span class="yellow-text">S</span>`; 
          }
          if(day <= 0 && hour <= 0){
            format =`${min}<span class="yellow-text">M</span> | ${sec}<span class="yellow-text">S</span>`;
          }
          self.content = format;
          }else{
           clearInterval(timer);
           self.content = self.endText;
           self._callback();
          }
         },1000);
        },
        _callback(){
        if(this.callback && this.callback instanceof Function){
           this.callback(...this);
         }
      }
    }
  }
</script>
<style >
.countdown-text{
  font-size: 13px;
  color: #fff;
}
.yellow-text{
  color: #ffd700!important;
}
</style>