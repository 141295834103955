<template>
  <TPop :visible.sync="isShow">
    <div class="box" @click="receive" v-loading="loading">
      <div v-if="!isSuecces">
        <div class="img-box" v-for="(item, index) in imgList" :key="index" :style="`z-index:${Math.floor(item.scale * 100)};
        left:${item.left}%;
        top:calc(${item.top}% - var(--width));
        animation-delay:${item.delay}s;
        --rotate:${item.rotate}deg;
        --scale:${item.scale};
        --duration:${5 / item.scale}s`">
          <img src="@/assets/images/redEnvelopeRainCard.png" alt="" />
        </div>
      </div>
      <div v-else class="suecces">
        <span>{{rainData?.currency}} {{ numFormat(price) }}</span>
        <img src="@/assets/images/redEnvelopeRainSuecces.png" alt="" />
        <div class="rain-box-close" @click="isShow = false"></div>
      </div>
      <img src="@/assets/images/redEnvelopeRainFoot.png" class="foot-img" />
      <img src="@/assets/images/redEnvelopeRainRight.png" class="right-img" />
    </div>
  </TPop>
</template>

<script>
import TPop from "@/components/TPop.vue";
import { red_packet_receive_api } from '@/api/redPacket';
export default {
  components: { TPop },
  data() {
    return {
      isShow: true,
      imgList: [],
      price: 0,
      isSuecces: false,
      loading:false
    };
  },
  props: {
    rainData: {
      default: null
    }
  },
  methods: {
    show() {
      this.isShow = true
      this.isSuecces = false
    },
    hide() {
      this.isShow = false
    },
    async receive() {
      if(this.loading) return
      if(this.isSuecces) return
      if (!this.$store.state.isLoginStatu) {
        this.isShow = false
        this.$router.push('?modal=LoginRegistration&data=tab-0')
        return
      }
      this.loading = true
      const { data: res } = await red_packet_receive_api({ rid: this.rainData.now_data.rid })
      this.loading = false
      if (res.code === 1) {
        this.isSuecces = true
        this.price = res.data
        this.updateUserInfo()
        this.$emit("receive")
      } else {
        this.isShow = false
        this.errorTips(res.msg);
      }
    },
    initImgList() {
      for (let i = 0; i < 100; i++) {
        this.imgList.push({
          left: Math.random() * 150,
          top: Math.random() * 5 - 20,
          delay: Math.random() * 5,
          rotate: Math.random() * 10 + 10,
          scale: Math.random() * 0.7 + 0.3,
          imgRotate: Math.random() * 360,
        });
      }
    },
  },
  created() {
    this.initImgList();
  },
};
</script>

<style scoped lang="less" src="@/assets/css/redEnvelopeRainAnimate.less"></style>
