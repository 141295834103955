<template>
  <el-dialog :visible.sync="isShow" :destroy-on-close="true" :close-on-click-modal="true"
    :modal-append-to-body="false">
    <div class="content-box">
      <div class="bonus-wapper">
        <img :src="require('@/assets/images/bonus-img.png')" class="bonus-img">
        <span class="equal">=</span>
        <span>{{ data.currency }} {{ data.amount }}</span>
      </div>
      <div class="five-bess-card">
        <div class="icon-item-box" v-for="item, index in data.card_list" :key="index">
          <img :src="item.image" class="bonus-icon">
          <span class="equal">=</span>
          <span>{{ data.currency }} {{ item.amount }}</span>
        </div>
      </div>
    </div>
    <div class="close-icon" @click="isShow = false">
      <img :src="require('@/assets/images/five-close-icon.png')" alt="">
    </div>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      isShow: false,
    }
  },
  props: {
    data: {
      default: () => ({})
    }
  },
  methods: {
    show() {
      this.isShow = true
    },
  }
}
</script>

<style scoped lang="less">
::v-deep .el-dialog {
  box-shadow: none;
  max-width: calc(100vw - 40px);
  min-width: 350px;
  width: 380px;
  background-color: transparent;
  background-image: url(~@/assets/images/five-tip-pop-bg.png);
  background-size: 100% 100%;
  margin: 0 auto 50px !important;

  .el-dialog__header {
    display: none;
  }

  .el-dialog__body {
    padding: 0;
    color: #FFF;
  }
}

.content-box {
  padding: 100px 30px 36px;
  font-size: 14px;
  justify-content: space-between;
  white-space: nowrap;
}

.five-bess-card {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 6px 10px;
  background: rgba(222,186,94,0.45);
  border-radius: 11px;
  border: 1px solid #EBB55E;
  padding: 20px 12px;
  width: 100%;
}

.bonus-img {
  width: 235px;

  @media (max-width:540px) {
    width: 210px;
  }
}

.bonus-wapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  grid-column: span 2;
  margin-bottom: 10px;

  @media (max-width:540px) {
    grid-column: span 1;
    margin-bottom: 20px;
  }

  span {
    margin-bottom: 6px;
  }
  .equal{
    margin: -4px 0 0;
  }
}

.equal {
  margin: 0 10px;
}

.bonus-icon {
  width: 40px;

  @media (max-width:540px) {
    width: 32px;
  }
}

.close-icon {
  width: 32px;
  height: 32px;
  cursor: pointer;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: calc(100% + 10px);

  @media (max-width:540px) {
    width: 27px;
    height: 27px;
  }

  img {
    width: 100%;
    height: 100%;
  }
}
</style>