<template>
  <div v-if="isShow" :class="turntableShow ? 'turntableShow' : 'turntableHide'" class="custom-dialog el-dialog-pdd" >
    <div v-if="turntableShow" class="ed-box">
      <div class="dio-title">
        <img src="@/assets/images/pdd/money-icon.png" class="dio-title-icon">
        <div class="dio-title-text" :data-text="data.title || '邀请好友获得奖金'">
          {{ data.title || $t("邀请好友获得奖金")}}
        </div>
      </div>
      <div class="dio-sub-title">
        <span :data-text="$t('奖金转盘')">{{ $t("奖金转盘") }}</span>
      </div>
      <div class="turntable-wapper">
        <turntable :borderShow="false" :data="data" @tabBtn="turntable" ref="turntable"></turntable>
      </div>
      <div class="bottom-desc">{{ $t("免费旋转并赢得真钱") }}</div>
    </div>
    <div v-else class="unveil-page">
      <div class="dio-title2">{{ data.title }}</div>
      <div class="cashText">{{ $t("恭喜你赢得") }}:</div>
      <div class="noWithdraw">
        <span>{{ data.current_symbol }}</span>
        <animate-number :formatter="formatNumber" from="0" :to="data.awarded_amount" :key="data.awarded_amount"
          duration="1500" easing="easeOutQuad" style="font-size: 40px;margin-left: 6px;"></animate-number>
      </div>

      <div class="jdt-wapper">
        <div class="jdt-txt">
          <span>{{ formatNumber(numFormat(data.awarded_amount / data.amount * 100 || 0)) }}%</span>
        </div>
        <div class="progress">
          <div class="progress-content" :style="`width: ${(data.awarded_amount / data.amount * 100 || 0)}%;`">
          </div>
        </div>
        <div class="needCash">{{ $t("您还需要") }}

          <span class="num">{{ data.current_symbol }}
            <animate-number :formatter="formatNumber" from="0" :to="numFormat((data.amount - data.awarded_amount) || 0)"
              duration="1500" :key="data.amount - data.awarded_amount" easing="easeOutQuad" from-color="#9663d1"
              to-color="#9663d1"></animate-number>
          </span>
          {{ $t("才能提款") }}
        </div>
        <button type="button" @click="goPddPage" class="cashButton ">{{ $t("要求取出更多") }}</button>
      </div>

      <div class="line"></div>
      <div class="withdrawTips">
        <span>{{ $t("成功取出") }}</span>
      </div>
      <div class="listContent">
        <div class="cashList" :style="{ 'animation-duration': `${data.withdrawal_user_list.length * 0.5}s` }">
          <div class="list-item" v-for="item, index in data.withdrawal_user_list" :key="index">
            <span>{{ item.username }}</span>
            <span>{{ $t('刚刚提现') }}</span>
            <span>{{ data.current_symbol }} {{ item.amount }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import turntable from "./turntable.vue"
import { pdd_getData_api, pdd_turntable_api } from '@/api/pdd.js'
export default {
  components: { turntable },
  data() {
    return {
      isShow: false,
      turntableShow: true,
      data: {},
      fristState: true
    }
  },
  watch: {
    "$store.state.isLoginStatu": {
      async handler() {
        if (this.$route.path == "/pdd") return
        await this.getData()
        if (this.data.length != 0) {
          this.isShow = true
        }
      }
    },
    isShow(v) {
      if (!v) {
        this.$router.push({ query: {} });
      }
    }
  },
  async mounted() {
    this.show()
  },
  methods: {
    verifyForThefirst() {
      const key = "pddPopHideTime";
      const date = new Date();
      const time = `${date.getFullYear()}/${date.getMonth() +
        1}/${date.getDate()}`;
      // 判断时间相等不显示，不相等进行显示
      console.log(localStorage.getItem(key))
      return time == localStorage.getItem(key);
    },
    async show() {
      this.isShow = true
      this.turntableShow = true
      if (this.$route.path == "/pdd") return
      if (this.data.awarded_amount > 0) {
        this.$router.push("/pdd")
        this.$emit('update:isShow', false)
      } else {
        await this.getData()
      }
    },
    hide() {
      this.isShow = false;
    },
    goPddPage() {
      this.turntableShow = true
      this.$router.push("/pdd")
    },
    // 抽奖
    async turntable() {
      if (!this.$store.state.isLoginStatu) {
        this.$router.push('/?modal=LoginRegistration&data=tab-0')
        return
      }
      if (this.tLoading) return
      if (!this.data.id) return
      if (this.data.awarded_amount > 0) {
        this.$router.push("/pdd")
        this.$emit('update:isShow', false)
        return
      }
      this.tLoading = true
      const { data } = await pdd_turntable_api({ id: this.data.id })
      if (data.code == 1) {
        this.$store.commit("msg/reduce", "pdd")
        this.$refs.turntable.run(async () => {
          this.tLoading = false
          if (data.data.amount > 0) {
            this.data.awarded_amount = data.data.amount
            setTimeout(() => {
              this.turntableShow = false
            }, 2000)
          } else {
            this.errorTips("很遗憾，您未中奖");
          }
        }, data.data.amount, data.data.type)
      } else {
        this.tLoading = false
        this.errorTips(data.msg);
      }
    },
    async getData() {
      const { data } = await pdd_getData_api()
      if (data.code == 1) {
        this.data = data.data
      } else {
        this.errorTips(data.msg)
      }
    }
  }
}
</script>

<style scoped lang="less">
.turntableShow {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;
  @media (max-width:540px) {
    width: 334px;
  }
}

.turntableHide {
  /deep/.el-dialog__header {
    padding: 0;
  }
}

.dio-title2 {
  padding: 18px 30px;
  color: #FFFFD6;
  background-color: #1B2737;
  margin: 0 -15px;
  font-size: 15px;
  font-weight: bold;
}

/deep/.el-dialog__headerbtn {
  position: absolute;
  top: 8px;
  right: 20px;
  padding: 0;
  background: 0 0;
  border: none;
  outline: 0;
  cursor: pointer;
  font-size: 16px;
}

.el-dialog__wrapper {
  background-color: rgba(0, 0, 0, .6);
  overflow: hidden;
}

/deep/.el-dialog__body {
  border: none;
  margin: 0;
  padding: 0;
}

.ed-box {
  padding: 25px 35px;
  background: linear-gradient(-2deg, #242625, #4D3B2D);
  border-radius: 14px;
  .title-icon {
    width: auto;
    height: 20px;
    object-fit: fill;
  }
}

.custom-dialog {
  padding: 0 !important;
}

.withdrawLogs {
  font-size: 13px;
  line-height: 13px;
  height: 20px;
  width: 100%;
  margin-left: 2px;
}

.van-notice-bar__wrap {
  position: relative;
  -webkit-box-flex: 1;
  flex: 1;
  align-items: center;
  height: 100%;
  overflow: hidden;
  display: flex;
}

.haveCash {
  font-size: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-weight: bold;
}

.dio-title {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 13px;

  .dio-title-text {
    display: flex;
    max-width: 100%;
    position: relative;
    font-family: Impact;
    font-weight: bold;
    letter-spacing: 0;
    font-size: 18px;
    display: block;
    background: linear-gradient(-2deg, #FFFFFF 13.3544921875%, #EFCE51 19.2138671875%, #FFAD55 36.5234375%, #EFCE51 60.693359375%, #FEFCA2 79.7607421875%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    @media (max-width:768px) {
      font-size: 16px;
      max-width: 80%;
    }
  }

  .dio-title-icon {
    width: 2em;
    margin-right: 10px;
  }

}
.dio-sub-title{
  height: 61px;
  background-image: url(~@/assets/images/pdd/pdd-subtitle-bg.png);
  background-size: 100% 100%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 32px;
  font-family: Microsoft YaHei UI;
  margin-bottom: 10px;
  span{
    position: relative;
    color: #9E6C2A;
    overflow: hidden;
    white-space: nowrap;
    max-width: 70%;
    text-overflow: ellipsis;
    &::after{
      content: attr(data-text);
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      background: linear-gradient(-2deg, #FFFFFF 0%, #EFCE51 7.4951171875%, #FCDB83 36.5234375%, #F8FDFC 79.7607421875%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      z-index: 3;
      transform: translate(1px,-2px);
      overflow: hidden;
      white-space: nowrap;
      max-width: 100%;
      text-overflow: ellipsis;
    }
  }
}

.top-desc {
  color: #FFF;
  text-align: center;
  font-size: 18px;
  margin-bottom: -30px;
  margin-top: 30px;

  @media (max-width:768px) {
    font-size: 14px;
    margin: 0;
    margin-top: 20px;
  }
}

.bottom-desc {
  background: #282D2E;
  border-radius: 6px;
  text-align: center;
  width: fit-content;
  margin: 0 auto;
  padding: 10px 20px;
  min-width: 80%;
  margin-top: 20px;
  color: #FFF;

  @media (max-width:768px) {
    font-size: 12px;
    margin-top: 14px;
    margin-bottom: 0;
  }
}

.turntable-wapper {
  position: relative;

  @media (max-width:768px) {
    margin: 0 auto;
  }
}

.unveil-page {
  width: 100%;
  padding: 0 15px 30px;

  img {
    width: auto;
    height: 20px;
    object-fit: fill;
  }
}

.cashText {
  font-size: 13px;
  color: #FFF;
  padding-top: 20px;
  text-align: center;
}

.noWithdraw {
  font-size: 30px;
  color: #FFF;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
}

.jdt-wapper {
  // margin-top: 8px;
  color: #fff;
  text-align: center;
}

.jdt-txt {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-size: 14px;
}

.progress {
  height: 23px;
  border-radius: 100vh;
  background: #93ACD3;
  box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.1) inset, 0px 1px 2px 0px #373737 inset;
  overflow: hidden;

  @media (max-width:768px) {
    height: 16px;
  }
}

.progress-content {
  max-width: 100%;
  width: 0%;
  height: 100%;
  background: linear-gradient(to top, #0165EB, #00B8FE);
  border-radius: 100vh;
  transition: all .3s;
}

.num {
  color: #9663d1;
  font-size: 1.3em;
}

.needCash {
  text-align: center;
  padding: 8px 0 16px;
}

.cashButton {
  width: 90%;
  height: 42px;
  background: linear-gradient(90deg, #A655F7, #CC9DFA);
  color: #fff;
  margin: 4px 0 18px;
  border-radius: 100vh;
}

.line {
  border: 1px dashed grey;
  margin-left: 35px;
  margin-right: 35px;
  margin-top: 1px;
}

.withdrawTips {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  line-height: 40px;
  font-family: SWISSC;
  color: #FFF;

  span {
    margin-left: 8px;
  }
}

.listContent {
  padding: 12px 0 6px;
  height: 120px;
  overflow: hidden;
}

.cashList {
  margin: 0 30px;
  animation: scrollList linear infinite;
}

@keyframes scrollList {
  from {
    transform: translateY(0%);
  }

  to {
    transform: translateY(-100%);
  }
}

.list-item {
  color: #94ADD6;
  margin-bottom: 8px;
  display: flex;
  justify-content: space-between;

  @media (max-width:768px) {
    font-size: 12px;
  }

  span {
    flex: 1;
    text-align: center;
    overflow: hidden;

    &:first-child {
      text-align: left;
    }

    &:last-child {
      text-align: right;
    }
  }
}

.el-icon-circle-close {
  font-size: 34px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: -50px;
  cursor: pointer;
  z-index: 2;
  color: #B8B6C7;

  &:hover {
    color: #FFF;
  }
}
</style>