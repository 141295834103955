<template>
  <div class="wg">
    <div class="wg-content">
      <div class="wg-content-unit">
        <img src="@/assets/images/dollar-icon-img.png">
        <span>{{ $helper.get("rule").currency.code }}</span>
      </div>
      <div class="wg-content-balances">
        <div class="wg-content-balances-item">
          <span class="wg-content-balances-item-title">{{ $t("现金") }}</span>
          <span class="wg-content-balances-item-price">{{ $helper.get("rule").currency.symbol }}{{
            numFormat($store.state.userInfo.balance) }}</span>
        </div>
        <div class="wg-content-balances-item">
          <div @click="tabQuestion">
            <svgIcon icon="icon-question" class="wg-content-balances-item-question"></svgIcon>
          </div>
          <span class="wg-content-balances-item-title">{{ $t("彩金") }}</span>
          <span class="wg-content-balances-item-price">{{ $helper.get("rule").currency.symbol }}{{
            numFormat($store.state.userInfo.cjbalance) }}</span>
        </div>
      </div>
      <span class="iconfont icon-jiazai_shuaxin wg-circle" :class="loading ? 'wg-circle-loading' : ''"
        @click="infoUpdate"></span>


    </div>
    <img src="@/assets/images/wg-banner.png" alt="" class="wg-banner" @click="$router.push('/all-game'); $emit('hide')">
  </div>
</template>

<script>
import svgIcon from "@/components/svg.vue";
export default {
  data() {
    return {
      loading: false
    }
  },
  components: { svgIcon },
  methods: {
    tabQuestion() {
      this.$emit("tabQuestion")
      this.$emit('hide')
    },
    async infoUpdate() {
      this.loading = true
      await this.updateUserInfo()
      this.loading = false
    }
  }
}
</script>

<style scoped lang="less" src="@/assets/css/walletDropdown.less"></style>