<template>
  <div class="category-tabs page-account__tabs">
    <swiper :options="swiperOpt" class="swiper">
      <swiper-slide v-for="item, index in tabsList" :key="index">
        <router-link :to="item.link" aria-current="page" :class="['tab', $route.path === item.link ? 'active' : '']">
          <span class="tab__icon">
            <img :src="item.icon" :alt="$t(item.name)" />
          </span>
          <span class="tab__title">{{ $t(item.name) }}</span>
        </router-link>
      </swiper-slide>
    </swiper>
    <button class="next-button swiper-button" id="category-tabs-swiper-next">
      <svgIcon icon="icon-arrow-right-small" class widthName="18" heightName="18"></svgIcon>
    </button>
    <button class="pre-button swiper-button" id="category-tabs-swiper-pre">
      <svgIcon icon="icon-arrow-left-small" class widthName="18" heightName="18"></svgIcon>
    </button>
  </div>
</template>

<script>
import svgIcon from "@/components/svg.vue";
export default {
  data() {
    return {
      tabsList: [
        { name: "我的账户", icon: require('@/assets/images/icon-my-account.79f1d6e.png'), link: "/account" },
        { name: "交易", icon: require('@/assets/images/icon-transactions.9e4cc46.png'), link: "/transactions" },
        { name: "账户历史", icon: require('@/assets/images/icon-game-history.c57a6ff.png'), link: "/account-history" },
        { name: "游戏历史", icon: require('@/assets/images/game-history.png'), link: "/game-history" },
        { name: "账户设置", icon: require('@/assets/images/setting.png'), link: "/setting" },
      ]
    }
  },
  computed:{
    swiperOpt(){
      return  {
        autoplay: false,
        slidesPerView: 5,
        initialSlide: this.tabsList.findIndex(item=>item.link === this.$route.path) - 1,
        breakpoints: {
          540: { slidesPerView: 3 },
          768: { slidesPerView: 4 }
        },
        navigation: {
          nextEl: "#category-tabs-swiper-next",
          prevEl: "#category-tabs-swiper-pre"
        },
      }
    }
  },
  components: { svgIcon }
};
</script>

<style scoped lang="less">
.category-tabs {
  background: #161f2c;
  border-radius: 14px;
  display: flex;
  align-items: center;
  position: relative;
  overflow: hidden;
}

.tab {
  border-radius: 14px;
  display: flex;
  align-items: center;
}

.tab {
  padding: 15px 25px;
  flex: 1 1;
  flex-direction: column;
  justify-content: center;
  text-decoration: none;
  position: relative;
  overflow: hidden;
  -webkit-transform: translateZ(0);
  -webkit-perspective: 1000;
  @media (max-width:768px) {
    padding: 8px 25px;
  }
}

.tab__icon {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  margin-bottom: 8px;
  position: relative;
  z-index: 2;
}

.tab__title {
  font-weight: 600;
  white-space: nowrap;
  z-index: 1;
  color: #fff;
  font-size: 16px;
  text-align: center;
}

.tab:before {
  pointer-events: none;
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0;
  z-index: 0;
  transition: opacity 0.1s ease-in-out;
}

.tab.active:before {
  background: linear-gradient(to bottom, #161D37, #4F1887);
  opacity: 1;
}

.tab:hover:not(.active):before {
  background: linear-gradient(180deg, rgba(22, 31, 44, 0), #1a2534);
  opacity: 1;
}

@media (max-width: 576px) {
  .tab__title {
    font-size: 12px;
  }

  .tab__icon {
    width: 30px;
    height: 30px;
  }
}

.swiper {
  width: 100%;
}

.swiper-button {
  padding: 0;
  width: 26px;
  background-color: #fff;
  color: #FFF;
  background-color: #4b4a4a;
  opacity: .3;
  position: absolute;
  height: 100%;
  transition: opacity 0.1s ease-in-out;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  user-select: none;
  z-index: 2;

  &:hover {
    opacity: .9;
  }
}

.next-button {
  right: 0;
}

.pre-button {
  left: 0;
}
.swiper-button-disabled{
  display: none;
}
</style>
