<template>
	<div class="modals-container" v-if="wheelShow">
		<div class="modal-wrapper" style="z-index: 1;" v-show="contentShow">
			<div class="modal fury-wheel-modal" style="max-width: 724px;">
				<div class="modal__container">
					<div class="fury-wheel" :class="playShow ? 'fury-wheel--play' : ''">
						<div class="fury-wheel__sound" v-if="!resultShow">
							<button class="sound-on" v-show="bgMusShow" @click="bgPlayPause">
								<svgIcon widthName="16" heightName="16" icon="icon-sound-on" className="fury-wheel__sound-icon">
								</svgIcon>
							</button>
							<button class="sound-off" v-show="!bgMusShow" @click="bgPlayPlay">
								<svgIcon widthName="16" heightName="16" icon="icon-sound-off" className="fury-wheel__sound-icon">
								</svgIcon>
							</button>
						</div>
						<div class="fury-wheel__head" v-if="!resultShow">
							<img src="../assets/images/luckyspin/lucky-spin.png"
								alt="" class="fury-wheel__racoon-img">
								<el-progress :percentage="allProgress" class="progress" :text-inside="true" :stroke-width="20" define-back-color="#FFDB88"
										text-color="#FFFFD6"></el-progress>
						</div>
						<div class="fury-wheel__wheel-wrap" v-if="!resultShow">
							<div class="fury-wheel__wheel-img">
								<img src="../assets/images/luckyspin/wheel-circle.png" alt="" class="fury-wheel__wheel-img">
							</div>
							<div class="fury-wheel__wheel-box">
								<LuckyWheel ref="myLucky" :width="wlWidth" :height="wlHeight" :defaultConfig="defaultConfig"
									:prizes="prizes" :blocks="blocks" :buttons="buttons" @start="startCallback" @end="endCallback" />
							</div>
							<div class="fury-wheel__wheel-foot">
								<img src="../assets/images/luckyspin/wheel_footer.png" alt="" class="fury-wheel__wheel-footer">
							</div>
							<div class="fury-wheel__wheel-cions">
								<img src="../assets/images/luckyspin/cions-1.png" alt="" class="fury-icon fury-icon-1">
								<img src="../assets/images/luckyspin/cions-2.png" alt="" class="fury-icon fury-icon-2">
								<img src="../assets/images/luckyspin/cions-3.png" alt="" class="fury-icon fury-icon-3">
								<img src="../assets/images/luckyspin/cions-4.png" alt="" class="fury-icon fury-icon-4">
								<img src="../assets/images/luckyspin/cions-5.png" alt="" class="fury-icon fury-icon-5">
								<img src="../assets/images/luckyspin/cions-6.png" alt="" class="fury-icon fury-icon-6">
							</div>
						</div>
						<!-- result -->
						<div class="wheel-win" v-if="resultShow">
							<div class="wheel-win__coins-block">
								<img
									src="@/assets/images/furyWheel/win-coins-fun.png"
									alt="" class="wheel-win__coins-fun">
							</div>
							<!-- <button type="button" class="button wheel-win__btn-back button_lg button_grey1 button_center" @click="resultShow = false">
								<span class="button__inner">
									<span class="button__text">
										<svgIcon widthName="16" heightName="16" icon="icon-arrow-left" className="link__icon"></svgIcon>
									</span>
								</span>
							</button> -->
							<div class="wheel-win__body">
								<div class="wheel-win__amount-wrap">
									<img :src="$t('wheel_fail')" alt="" class="wheel-win__label-img" v-if="prize.type === 3">
									<img :src="$t('wheel_win')" alt="" class="wheel-win__label-img" v-else>
									<div class="wheel-win__amount-block"  v-if="prize.type !== 3">
										<img :src="require('@/assets/images/' + $store.state.currencyIcon)" class="">
										<p class="wheel-win__amount-sum">{{ numFormat(prize.amount) }}</p>
										<p class="wheel-win__currency-name">
											<!-- {{ prize.title }} -->
										</p>
									</div>
								</div>
								<p class="wheel-win__desc">
									{{ prize.type === 3 ? $t('下次一定会中奖') : $t('win_lottery_prize_desc') }}
									<!-- <span class="wheel-win__desc--orange">share $150 prize pool</span> -->
								</p>
								<div class="wheel-win__btn-block">
									<button type="button" v-if="this.free_count == 0" @click="startBack"
										class="button button_lg button_red button_fullwidth button_center">
										<span class="button__inner">
											<span class="button__text">{{ $t('继续游玩') }}</span>
										</span>
									</button>
									<button type="button" v-else @click="startAgain"
										class="button button_lg button_red button_fullwidth button_center">
										<span class="button__inner">
											<span class="button__text">{{ $t('继续游玩') }}</span>
										</span>
									</button>
									<button type="button" @click="wheelClose"
										class="button button_lg btn_gray button_fullwidth button_center">
										<span class="button__inner">
											<span class="button__text">{{ $t('取消') }}</span>
										</span>
									</button>
								</div>
							</div>
						</div>
						<div class="fury-wheel__body" v-if="!resultShow">
							<div class="fury-wheel__group">
								<!-- 显示抽奖次数 -->
								<button class="fury-wheel__btn" @click="startCallback"
									v-if="prizeConfig && prizeConfig.required_balance != null && prizeConfig.day_time > 0">
									<span>{{ prizeConfig.day_time }} {{ $t('剩余') }}</span>
								</button>
								<!-- 显示任务进度 -->
								<template v-if="prizeConfig.day_time === 0">
									<button class="fury-wheel__btn" @click="() => $refs.rotaryProgressBar.show()" v-if="task_status == 0">
										<span> {{ $t("查看进度") }}</span>
									</button>
									<el-button class="fury-wheel__btn" @click="lotteryReceiveCount" v-else-if="task_is_receive==0" :loading="lotteryReceiveCountLoaidng">
										<span> {{ $t("领取") }}</span>
									</el-button>
									<el-button class="fury-wheel__btn fury-wheel__btn_dis"  :disabled="true" v-else-if="task_is_receive==1">
										<span> {{ $t("已领取完") }}</span>
									</el-button>
								</template>

								<div class="fury-less-time" v-show="this.free_count == 0 && this.$helper.get('timehelper')">
									<el-statistic ref="statistic" format="HH:mm:ss" :value='$helper.get("timehelper")' @finish="timeover"
										title="" :value-style="valueStyle" time-indices>
									</el-statistic>
								</div>
							</div>
							<button class="link fury-wheel__link link_md" @click="historyShow = true">
								<span class="link__text">{{ $t('中奖历史') }}</span>
								<span class="el-icon-arrow-right"></span>
							</button>
							<button v-if="!prizeRequest" class="modal__btn-close" @click="wheelClose">
								<img src="../assets/images/luckyspin/icon-close.png" alt="">
							</button>
						</div>
					</div>
					<div class="wheel-history " :class="historyShow ? 'wheel-history--show' : ''">
						<div class="wheel-history__content">
							<div class="wheel-history__head" @click="historyShow = false">
								<svgIcon widthName="16" heightName="16" icon="icon-arrow-left" className="buttonlink__icon">
								</svgIcon>
								<h3 class="wheel-history__title">{{ $t('Recent and big wins') }}</h3>
							</div>
							<div class="wheel-history__body">
								<div class="wheel-history__tabs">
									<div class="wheel-history__tab wheel-history__tab--active">
										{{ $t('中奖历史') }}
									</div>
								</div>
								<div class="wheel-history__table">
									<div class="wheel-history__row wheel-history__row--recent">
										<div class="wheel-history__col wheel-history__col--head">{{ $t('Time') }}</div>
										<div class="wheel-history__col wheel-history__col--head">{{ $t('User') }}</div>
										<div class="wheel-history__col wheel-history__col--head">{{ $t('Prize') }}</div>
									</div>
									<div class="wheel-history__row wheel-history__row--recent" v-for="(item, index) in records"
										:key="index">
										<div class="wheel-history__col wheel-history__col--time">
											<span class="datetime history__time">{{ item.time }}</span>
										</div>
										<div class="wheel-history__col">
											<div class="user user--clickable">
												<div class="user__avatar" style="width: 22px; height: 22px;">
													<div class="user__inner">
														<img height="22" width="22" :src="item.avatar" alt="">
													</div>
												</div>
												<div class="user__wrap">
													<span class="user__name">{{ item.username }}</span>
												</div>
											</div>
										</div>
										<div class="wheel-history__col">
											<span class="currency">
												<img width="20" height="20" :src="require('@/assets/images/' + $store.state.currencyIcon)"
													class="currency__icon">
												<span style="font-size: 12px;">
													<span style="color: #ffffff" class="font-digits">{{ numFormat(item.prize_price) }}</span>
												</span>
											</span>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div style="height: 0; overflow: hidden;">
			<audio :src="clickMus" class="media-audio" ref="MusicPlay"></audio>
			<audio :src="roundMus" class="media-audio" ref="MusicRound"></audio>
			<audio :src="resultMus" class="media-audio" ref="MusicResult"></audio>
			<audio :src="enterMus" class="media-audio" ref="MusicEnter"></audio>
			<audio :src="bgMus" class="media-audio" loop ref="MusicBg"></audio>
		</div>
		<wheelTip ref="wheelTipDialog" :prizeConfig="prizeConfig" @eventComfirm="eventComfirm"></wheelTip>
		<rotaryProgressBar ref="rotaryProgressBar" :task_rate="task_rate" @toInfo="$emit('wheelClose');" @changeShow="v=>contentShow = !v"></rotaryProgressBar>
	</div>
</template>
<script>
import svgIcon from "@/components/svg.vue";
import wheelTip from "@/components/wheelTip.vue";
import { lottery_api, lottery_record_api, lottery_config_api,lottery_receive_count_api } from "@/api/lottery";
import rotaryProgressBar from '@/components/rotaryProgressBar.vue';
export default {
	components: {
		svgIcon,
		wheelTip,
		rotaryProgressBar
	},
	props: {
		wheelShow: {
			type: Boolean,
			default: false
		},
	},
	data() {
		return {
			contentShow:true,
			wlWidth: 490,
			wlHeight: 490,
			wlPartWidth: 90,
			wlPartHeight: 142,
			playShow: false,
			historyShow: false,
			blocks: [{
				padding: '2px',
				imgs: []
			}],
			prizes: [],
			buttons: [{
				radius: '100px',
				pointer: true,
				fonts: [{
					text: "", //this.$t('开始')
					top: '-10px',
					fontColor: "#ffffff"
				}],
				imgs: [{
					top: '-60px',
					width: '120px',
					height: '120px',
					src: require("@/assets/images/luckyspin/spin@2x.png")
				}]
			}],
			defaultConfig: {
				stopRange: 0,
				gutter: 10,
				offsetDegree: -15
			},
			winlist: [],
			swiperOptionThumbs: {
				loop: true,
				autoplay: {
					delay: 2500,
					disableOnInteraction: false,
				},
				observer: true,
				observeParents: true,
				slidesPerView: '2',
				spaceBetween: 5,
				breakpoints: {
					640: {
						slidesPerView: '1',
					},
				}
			},
			resultShow: false,
			bgMusShow: false,
			clickMus: require("../assets/mp3/click.mp3"),
			roundMus: require("../assets/mp3/round.mp3"),
			resultMus: require("../assets/mp3/result.mp3"),
			bgMus: require("../assets/mp3/bg.mp3"),
			enterMus: require("../assets/mp3/enter.mp3"),
			prizeConfig: null,
			prizesIdByIndex: {},
			prizeRequest: false,
			prize: null,
			records: [],


			free_count: 0, //抽奖次数
			limit_count: 0,
			balance: 0,
			timestamp: '',
			task_rate:[],
			task_status:0,
			task_is_receive:0,
			id:null,
			lotteryReceiveCountLoaidng:false,
			valueStyle: {
				color: "#ffffff",
				fontSize: "22px"
			}
		};
	},
	computed:{
		allProgress(){
      if(this.task_rate?.length){
        let progressAll = 0
        this.task_rate.forEach(item=>{
          progressAll += this.progress(item.condition,item.complete);
        })
        return Math.floor(progressAll/this.task_rate.length)
      }
      return 0;
    }
  },
	methods: {
		// 抽奖
		async lotteryReceiveCount(){
			this.lotteryReceiveCountLoaidng = true
			const {data:res} = await lottery_receive_count_api({id:this.id})
			if(res.code==1){
				await this.getFreeCount();
				this.$message({
          message: this.$t("领取成功"),
          type: 'success'
        });
			}
			this.lotteryReceiveCountLoaidng = false
		},
		// 计算进度
		progress(condition, complete) {
      const progress = Math.floor((complete / condition) * 100)
      return (progress > 100 ? 100 : progress) || 0
    },
		bgPlayPause() {
			this.$refs.MusicBg.pause()
			this.$refs.MusicPlay.pause();
			this.$refs.MusicRound.pause();
			this.$refs.MusicResult.pause();
			this.bgMusShow = false
		},
		bgPlayPlay() {
			this.$refs.MusicBg.play()
			this.bgMusShow = true
		},
		wheelClose() {
			this.$emit("wheelClose");
			this.resultShow = false
			this.playShow = false
		},
		timeover() {
			this.getFreeCount()
			if (this.$helper.get("timehelper")) {
				this.$helper.rm("timehelper");
			}
		},
		getFreeCount(time) {
			let self = this
			lottery_config_api().then(response => {
				let res = response.data
				if (res && res.code == 1) {
					self.free_count = res.data.free_count;
					self.limit_count = res.data.limit_count;
					self.prizeConfig = res.data
					self.balance = res.data.balance;
					self.task_rate = res.data.task_rate;
					self.task_status = res.data.task_status;
					self.task_is_receive = res.data.task_is_receive;
					if (time && time != '') {
						setTimeout(() => {
							if (!this.$helper.get("timehelper")) {
								this.$helper.set("timehelper", Date.now() + 1000 * parseInt(res.data.free_time));
							}
						}, 7000)
					}
				}
			});
		},
		startCallback() {
			if (this.prizeRequest) {
				this.tips('请勿频繁请求');
				return;
			}
			if (this.free_count == 0) {
				// 判断余额为0，消耗积分，提示积分不足
				if(Number(this.prizeConfig.required_balance ) === 0 && this.prizeConfig.integral < this.prizeConfig.required_integral){
					this.errorTips('积分不足');
					return
				}

				// 判断积分为0，消耗余额，提示积分不足
				if(this.prizeConfig.required_integral === 0 && Number(this.prizeConfig.required_balance) > Number(this.prizeConfig.balance)){
					this.errorTips('余额不足');
					return
				}

				// 不为0判断，和缓存
				if (!this.$helper.get("tipStorage") && (this.prizeConfig.required_integral > 0 || this.prizeConfig.required_balance > 0) ) {
					this.$store.commit("$vuexSetWheelTipShowState", true);
					return;
				}
			}
			this.prizeRequest = true;
			let self = this

			lottery_api().then(response => {
				let res = response.data
				if (res && res.code == 1) {
					self.playShow = true
					self.prize = res.data
					// 重新获取次数
					this.getFreeCount('timesss')
					// 音乐
					this.bgMusShow && self.$refs.MusicPlay.play();
					setTimeout(() => {
						this.bgMusShow && self.$refs.MusicRound.play();
					}, 500)
					// 转盘事件
					self.$refs.myLucky.play()
					setTimeout(() => {
						// 后端返回的中奖索引
						const index = self.prizesIdByIndex[res.data.id]
						// 调用stop停止旋转并传递中奖索引
						self.$refs.myLucky.stop(index)
					}, 4500)
					setTimeout(() => {
						self.resultShow = true
						self.updateUserInfo();
						this.bgMusShow && self.$refs.MusicResult.play();
						self.prizeRequest = false;
					}, 7000)
				} else {
					self.errorTips(res.msg);
					self.prizeRequest = false;
				}
			});
		},
		// 第一次花钱玩
		eventComfirm() {
			this.$helper.set("tipStorage", true);
			this.updateUserInfo();
			this.startCallback()
		},
		// 继续玩
		startAgain() {
			this.startCallback()
			this.resultShow = false
		},
		// 继续玩
		startBack() {
			this.resultShow = false
			this.playShow = false
		},
		// 抽奖结束会触发end回调
		endCallback(prize) {
			// console.log('endCallback', prize)
		},
		wheelSize() {
			if (document.body.clientWidth < 992) {
				this.wlWidth = 280
				this.wlHeight = 280
				this.wlPartWidth = 90
				this.wlPartHeight = 100
			} else {
				this.wlWidth = 400
				this.wlHeight = 400
				this.wlPartWidth = 125
				this.wlPartHeight = 140
			}
			window.onresize = () => {
				return (() => {
					if (this.windowWidth < 992) {
						this.wlWidth = 280
						this.wlHeight = 280
						this.wlPartWidth = 64
						this.wlPartHeight = 100
					} else {
						this.wlWidth = 380
						this.wlHeight = 380
						this.wlPartWidth = 90
						this.wlPartHeight = 142
					}
				})();
			};
		},
		initPrizes(prizes) {
			for (const key in prizes) {
				if (Object.hasOwnProperty.call(prizes, key)) {
					const element = prizes[key];
					this.prizesIdByIndex[element.id] = key;
					this.prizes.push({
						fonts: [{
							text: this.$t(element.title),
							fontSize: '0',
							fontColor: '#ffffff',
							top: '-300'
						}],
						imgs: [{
							top: '-2',
							width: this.wlPartWidth,
							height: this.wlPartHeight,
							src: element.pic
						}]
					})
				}
			}
		},
		// 奖品配置列表
		async getLotteryConfig() {
			await lottery_config_api().then(response => {
				let res = response.data
				if (res && res.code == 1) {
					this.initPrizes(res.data.Prize)
					this.prizeConfig = res.data
					this.free_count = res.data.free_count;
					this.balance = res.data.balance;
					this.limit_count = res.data.limit_count;
					this.task_rate = res.data.task_rate;
					this.task_status = res.data.task_status;
					this.task_is_receive = res.data.task_is_receive;
					this.id = res.data.id;
					console.log(this.prizes,'this.prizes')
					console.log(this.prizeConfig,'this.prizeConfig')
				}
			});
		},
		// 抽奖记录
		getLotteryRecord() {
			let self = this
			lottery_record_api().then(response => {
				let res = response.data
				if (res && res.code == 1) {
					for (const key in res.data.data) {
						if (Object.hasOwnProperty.call(res.data.data, key) && self.winlist) {
							const element = res.data.data[key];
							self.winlist.push({
								icon: require('@/assets/images/' + this.$store.state.currencyIcon),
								value: element.prize_price,
								uname: element.username
							})
						}
					}
					self.records = res.data.data
				}
			});
		},
	},
	async mounted() {
		await Promise.all([
			this.wheelSize(),
			this.getLotteryConfig(),
			this.getLotteryRecord()
		])

		if (this.$route.query.dialog === 'wheel' && this.$store.state.isLoginStatu) {
			this.$parent.wheelClick()
		}
	},
	watch: {
		wheelShow(val) {
			if (val && this.prizes.length == 0 && this.$store.state.isLoginStatu) {
				this.getLotteryConfig();
				this.getLotteryRecord();
			} else if (!val) {
				if (this.$route.query.dialog == "wheel") {
					this.$router.push(this.$route.path)
				}
			}
		}
	},
};
</script>

<style>
.gallery-fury-wheel .swiper-slide {
	width: auto;
}

.modals-container {
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	z-index: 9000
}

.modal-wrapper {
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	text-align: center;
	white-space: nowrap;
	overflow-y: auto;
	overflow-x: hidden;
	padding: 16px;
	background-color: rgba(3, 11, 21, .85)
}

@media(max-width:568px) {
	.modal-wrapper {
		padding: 8px
	}
}

.modal-wrapper:before {
	content: "";
	height: 100%;
	width: 0
}

.modal-wrapper:before,
.modal-wrapper>div {
	display: inline-block;
	vertical-align: middle
}

.modal-wrapper>div {
	width: 100%;
	text-align: left;
	position: relative;
	white-space: normal;
	overflow: hidden;
	border-radius: 12px;
	opacity: 1;
	transform: translateZ(0)
}

.fury-wheel {
	position: relative;
	height: 100%
}

.fury-wheel--play .fury-wheel__wheel-help-wrap {
	-webkit-animation-play-state: running;
	animation-play-state: running
}

.fury-wheel--play .fury-wheel__wheel-help {
	-webkit-animation-play-state: paused;
	animation-play-state: paused
}

.fury-wheel--play .fury-wheel__body {
	-webkit-animation: bodyHide-data-v-5b9beddd .6s linear forwards;
	animation: bodyHide-data-v-5b9beddd .6s linear forwards
}

@media(max-width:767px) {
	.fury-wheel--play .fury-wheel__body {
		-webkit-animation: bodyHideMobile-data-v-5b9beddd .6s linear forwards;
		animation: bodyHideMobile-data-v-5b9beddd .6s linear forwards
	}
}

.fury-wheel--play .fury-wheel__winners {
	-webkit-animation: winnersHide-data-v-5b9beddd .6s linear forwards;
	animation: winnersHide-data-v-5b9beddd .6s linear forwards
}

.fury-wheel--play .fury-wheel__wheel-wrap {
	-webkit-animation: wheelCenter-data-v-5b9beddd .6s linear forwards;
	animation: wheelCenter-data-v-5b9beddd .6s linear forwards
}

@media(max-width:767px) {
	.fury-wheel--play .fury-wheel__wheel-wrap {
		-webkit-animation: wheelCenterMobile-data-v-5b9beddd .6s linear forwards;
		animation: wheelCenterMobile-data-v-5b9beddd .6s linear forwards;
		z-index: 5
	}

	.fury-wheel--play .fury-wheel__wheel-wrap:before {
		display: none
	}
}

.fury-wheel--play .fury-wheel__bg-wheel-img {
	-webkit-animation: bgImgCenter-data-v-5b9beddd .6s linear forwards;
	animation: bgImgCenter-data-v-5b9beddd .6s linear forwards
}

@media(max-width:767px) {
	.fury-wheel--play .fury-wheel__bg-wheel-img {
		-webkit-animation: bgImgCenterMobile-data-v-5b9beddd .6s linear forwards;
		animation: bgImgCenterMobile-data-v-5b9beddd .6s linear forwards
	}
}


.fury-wheel--change .fury-wheel__wheel-wrap {
	-webkit-animation: changeToWin-data-v-5b9beddd .6s linear forwards;
	animation: changeToWin-data-v-5b9beddd .6s linear forwards
}

.fury-wheel__sound {
	position: absolute;
	z-index: 9;
	top: 19px;
	right: 60px
}

.fury-wheel__sound-icon {
	fill: #8e939e
}

.fury-wheel__sound-icon--active {
	fill: #9663d1;
	fill: var(--color_blue)
}

.fury-wheel__sound-icon:hover {
	fill: #fff
}

.fury-wheel img {
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	pointer-events: none
}

@media(max-width:767px) {
	.fury-wheel__bg-wheel-img {
		left: 50%;
		top: 50%;
		width: 647px;
		max-width: 647px;
		height: 647px;
		transform: translate(-50%, -70%) scale(0);
		-webkit-animation: wheelDecorShowMobile-data-v-5b9beddd 1.2s linear .6s forwards;
		animation: wheelDecorShowMobile-data-v-5b9beddd 1.2s linear .6s forwards;
		z-index: 5
	}
}

.fury-wheel__wheel-wrap {
	position: relative;
	margin-left: auto;
	margin-right: auto;
	background: linear-gradient(180deg, #ffbf14, #8c4d00);
	width: 400px;
	height: 400px;
	border-radius: 50%;
	margin-top: 40px;
	margin-bottom: 70px;
	z-index: 9;
	animation: wheelBody .6s linear .4s forwards;
}

.fury-wheel__wheel-wrap::before{
	content: '';
    position: absolute;
    z-index: 199;
    top: -25px;
    left: 50%;
    width: 128px;
    height: 54px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    margin-left: -64px;
	background-image: url(../assets/images/luckyspin/wheel-arror.png);
}



@media(max-width:767px) {
	.fury-wheel__wheel-wrap::before{
		content: '';
        position: absolute;
        z-index: 199;
        top: -18px;
        left: 50%;
        width: 84px;
        height: 39px;
        margin-left: -42px;
	}
	.fury-wheel__wheel-wrap {
		width: 280px;
		height: 280px;
		-webkit-animation: wheelShowMobile-data-v-5b9beddd .6s linear .4s forwards;
		animation: wheelShowMobile-data-v-5b9beddd .6s linear .4s forwards;
		margin-bottom: 30px;
	}
}

.fury-wheel__arrow-img {
	position: absolute;
	left: 50%;
	top: 30px;
	transform: translate(-48%, -50%);
	width: 86px;
	height: 31px;
	z-index: 2;
}

.fury-wheel__arrow-img--win-pulse {
	-webkit-animation: pulse-arrow-data-v-5b9beddd .6s linear !important;
	animation: pulse-arrow-data-v-5b9beddd .6s linear !important
}

@media(max-width:767px) {
	.fury-wheel__arrow-img--win-pulse {
		-webkit-animation: pulse-arrow-mobile-data-v-5b9beddd .6s linear !important;
		animation: pulse-arrow-mobile-data-v-5b9beddd .6s linear !important
	}
}

.fury-wheel__arrow-img--disabled {
	-webkit-animation: none;
	animation: none
}

@media(max-width:767px) {
	.fury-wheel__arrow-img {
		left: 50%;
		transform: translateX(-50%);
		top: 32px;
		width: 64px;
		height: 23px;
		z-index: 1111;
	}
}
.fury-wheel__wheel-box{
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	width: 400px;
	height: 400px;
	z-index: 111;
	
	transition-property: transform;
    background: linear-gradient(180deg, #ffbf14, #8c4d00);
	border-radius: 50%;
	transition-timing-function: cubic-bezier(.19, -.11, .03, 1.04)
}
.fury-wheel__wheel-img {
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	width: 452px;
	height: 452px;
	z-index: 111;
	transition-property: transform;
	transition-timing-function: cubic-bezier(.19, -.11, .03, 1.04)
}

@media(max-width:767px) {
	.fury-wheel__wheel-img {
		width: 316px;
        height: 316px;
	}
}

.fury-wheel__wheel-help-wrap {
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	width: 458px;
	height: 458px;
	-webkit-animation: rolling-data-v-5b9beddd 5s linear .6s infinite;
	animation: rolling-data-v-5b9beddd 5s linear .6s infinite;
	-webkit-animation-play-state: paused;
	animation-play-state: paused
}

.fury-wheel__wheel-help-wrap-3,
.fury-wheel__wheel-help-wrap-5,
.fury-wheel__wheel-help-wrap-7 {
	animation-direction: reverse
}

@media(max-width:767px) {
	.fury-wheel__wheel-help-wrap {
		width: 350px;
		height: 350px
	}
}

.fury-wheel__wheel-help {
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	-webkit-animation: rolling-data-v-5b9beddd 20s linear .6s infinite;
	animation: rolling-data-v-5b9beddd 20s linear .6s infinite;
	-webkit-animation-play-state: running;
	animation-play-state: running
}

.fury-wheel__wheel-help-3,
.fury-wheel__wheel-help-5,
.fury-wheel__wheel-help-7 {
	animation-direction: reverse
}

.fury-wheel__wheel-btn {
	position: absolute;
	padding: 0;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%) scale(1.1);
	background: linear-gradient(270deg, #010958, #1108bf);
	border-radius: 50%;
	width: 99px;
	height: 99px;
	cursor: pointer;
	z-index: 9;
	-webkit-animation: pulse-btn-data-v-5b9beddd 2.5s linear 1s infinite;
	animation: pulse-btn-data-v-5b9beddd 2.5s linear 1s infinite
}

.fury-wheel__wheel-btn--win-pulse {
	-webkit-animation: pulse-btn-data-v-5b9beddd .6s linear !important;
	animation: pulse-btn-data-v-5b9beddd .6s linear !important
}

.fury-wheel__wheel-btn--disabled {
	pointer-events: none;
	-webkit-animation: none;
	animation: none
}

.fury-wheel__wheel-btn--disabled .fury-wheel__btn-img {
	opacity: .3
}

.fury-wheel__wheel-btn:hover .fury-wheel__btn-content,
.fury-wheel__wheel-btn:hover .fury-wheel__btn-wrap {
	background: linear-gradient(180deg, #ff9c29, #ff2e00)
}

.fury-wheel__wheel-btn:active .fury-wheel__btn-img {
	opacity: .8
}

@media(max-width:767px) {
	.fury-wheel__wheel-btn {
		width: 70px;
		height: 70px
	}
}

.fury-wheel__btn-wrap {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50.5%, -50.5%);
	display: flex;
	align-items: center;
	justify-content: center;
	background: linear-gradient(180deg, #ffb629, #ff3d00);
	box-shadow: 0 3.42188px 5.70312px rgba(0, 0, 0, .4);
	width: 91px;
	height: 91px;
	border-radius: 50%
}

@media(max-width:767px) {
	.fury-wheel__btn-wrap {
		transform: translate(-50%, -50%);
		width: 65px;
		height: 65px
	}
}

.fury-wheel__btn-content {
	position: relative;
	width: 68px;
	height: 68px;
	background: linear-gradient(180deg, #ffb629, #ff3d00);
	transform: matrix(1, 0, 0, -1, 0, 0);
	border-radius: 50%;
	border: 1px solid #ffc58f
}

@media(max-width:767px) {
	.fury-wheel__btn-content {
		width: 45px;
		height: 45px
	}
}

.fury-wheel__btn-img {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-52%, -55%) matrix(1, 0, 0, -1, 0, 0);
	width: 41px;
	height: 41px
}

@media(max-width:767px) {
	.fury-wheel__btn-img {
		width: 28px;
		height: 28px
	}
}

.fury-wheel__body {
	position: relative;
	text-align: center;
	z-index: 33;
	animation: wheelFooter 1s ease-in 1 alternate;
	animation-delay: .1s;
}


.fury-wheel__group {
	margin: 0 auto;
	margin-bottom: 12px;
}

.fury-wheel__btn {
	position: relative;
	margin: 0 auto;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 360px;
	height: 70px;
	background: linear-gradient( 180deg, #74ECC8 0%, #48A689 35%, #115E41 83%, #0CB674 100%);
	border-radius: 39px;
	border: 3px solid;
	font-family: PingFangSC, PingFang SC;
	font-weight: 600;
	font-size: 30px;
	color: #FEDA54;
	line-height: 23px;
	padding: 0 20px;
	overflow: hidden
}
.fury-wheel__btn_dis{
	background: linear-gradient(180deg, rgb(22, 8, 25), #02131b) !important;
	color: #FFF !important;
}
.fury-wheel__btn span {
	font-weight: 600;
	font-size: 30px;
}

.fury-wheel__less {
	height: 50px;
	background: #000C51;
	border-radius: 14px;
	display: flex;
	align-items: center;
	justify-content: center;
	line-height: 1;
}

.fury-wheel__less .vnd-light {
	width: 22px;
	width: 22px;
	margin-right: 8px;
}

.fury-wheel__btn:before {
	left: 20px;
	width: 20px
}

.fury-wheel__btn:after,
.fury-wheel__btn:before {
	content: "";
	position: absolute;
	top: 50%;
	height: 90px;
	background: #fff;
	opacity: .4;
	filter: blur(5px);
	transform: translate(-150px, -50%) rotate(45deg);
	-webkit-animation: highlight-btn-data-v-5b9beddd 3s linear infinite;
	animation: highlight-btn-data-v-5b9beddd 3s linear infinite
}

.fury-wheel__btn:after {
	left: 80px;
	width: 30px
}

@media(max-width:767px) {
	.fury-wheel__btn {
		width: 180px;
		height: 40px;
		pointer-events: all;
		margin: 0 auto 
	}

}

.fury-wheel__link {
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 20px;
	color: #E8E5D4;
	line-height: 40px;
	margin: auto
}
.fury-wheel__link .el-icon-arrow-right{
	font-size: 20px;
}
@media(max-width:767px) {
	.fury-wheel__link {
		line-height: 20px;
		font-size: 15px;
		pointer-events: all
	}
}

.fury-wheel__link svg {
	fill: #E8E5D4;
	margin-left: 4px
}

.fury-wheel__timer-label {
	font-weight: 600;
	font-size: 14px;
	line-height: 18px;
	color: #8f9ef6;
	text-align: center;
	margin-bottom: 8px
}

.fury-wheel__timer-block {
	margin: 30px 0 48px
}

@media(max-width:767px) {
	.fury-wheel__timer-block {
		margin: 12px 0
	}
}

.fury-wheel__timer,
.fury-wheel__timer-time {
	display: flex;
	align-items: center;
	justify-content: center
}

.fury-wheel__timer-time {
	position: relative;
	width: 46px;
	height: 40px;
	font-weight: 700;
	font-size: 24px;
	line-height: 32px;
	background: linear-gradient(180deg, #7c1da9, #5d32ef);
	border-radius: 8px;
	margin-right: 22px
}

.fury-wheel__timer-time:before {
	content: ":";
	position: absolute;
	top: 50%;
	right: -16px;
	transform: translateY(-60%);
	font-weight: 400;
	font-size: 48px;
	line-height: 36px;
	color: #5e4190
}

@media(max-width:767px) {
	.fury-wheel__timer-time:before {
		font-size: 36px;
		right: -15px
	}
}

.fury-wheel__timer-time:last-child {
	margin-right: 0
}

.fury-wheel__timer-time:last-child:before {
	display: none
}

.fury-wheel__footer {
	width: 100%;
	max-width: 500px;
	margin-left: auto;
	margin-top: 12px;
	overflow: hidden;
	z-index: 3
}

@media(max-width:767px) {
	.fury-wheel__footer {
		max-width: 100%
	}
}

.fury-wheel__winners {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	flex-direction: row-reverse;
	position: relative;
	padding-right: 13px;
	border-radius: 15px;
	margin-bottom: 12px;
	opacity: 0;
	-webkit-animation: winnersShow-data-v-5b9beddd .6s linear .6s forwards;
	animation: winnersShow-data-v-5b9beddd .6s linear .6s forwards
}

.fury-wheel__winners:before {
	content: "";
	position: absolute;
	left: -30px;
	top: 0;
	width: 231px;
	height: 100%;
	background: linear-gradient(90deg, #010939 12.86%, rgba(1, 9, 57, 0));
	z-index: 2
}

.fury-wheel__winner {
	display: flex;
	align-items: center;
	justify-content: center;
	font-weight: 600;
	font-size: 12px;
	line-height: 16px;
	background: rgba(110, 102, 244, .21);
	border-radius: 15px;
	padding: 4px 6px;
	margin-left: 4px;
	min-width: 16%;
	transition: all 1s;
	-webkit-animation-duration: .5s;
	animation-duration: .5s;
	-webkit-animation-direction: normal;
	animation-direction: normal;
	-webkit-animation-delay: 0s;
	animation-delay: 0s;
	-webkit-animation-play-state: running;
	animation-play-state: running;
	-webkit-animation-timing-function: ease-out;
	animation-timing-function: ease-out;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.fury-wheel__winner:first-child {
	-webkit-animation-name: horizont-1-data-v-5b9beddd;
	animation-name: horizont-1-data-v-5b9beddd
}

.fury-wheel__winner:nth-child(2n) {
	-webkit-animation-name: horizont-2-data-v-5b9beddd;
	animation-name: horizont-2-data-v-5b9beddd
}

.fury-wheel__winner:nth-child(odd) {
	-webkit-animation-name: horizont-3-data-v-5b9beddd;
	animation-name: horizont-3-data-v-5b9beddd
}

.fury-wheel__winner img {
	margin-right: 6px
}

@media(max-width:767px) {
	.fury-wheel__winner {
		min-width: 25%
	}
}




.wheel-win {
	background-color: #0c2f2b;
	width: 450px;
	max-width: 90%;
	padding: 40px 20px 0;
	margin: auto;
	border-radius: 20px;
	position: relative;
	border: 6px solid #E0BF55;
}


.wheel-win img {
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	pointer-events: none;
}

.wheel-win .wheel-win__btn-back {
	position: absolute;
	left: 16px;
	top: 16px;
	z-index: 5;
	width: 32px;
	height: 32px;
	padding: 0;
	display: flex;
	justify-content: center;
	align-items: center
}

.wheel-win__coins-block {
	position: absolute;
	left: -20%;
	right: -20%;
	bottom: -15%;
	display: flex;
	align-items: center;
	justify-content: flex-end;
}

.wheel-win__coins {
	position: absolute;
	left: -20px;
	top: -20px;
	right: -20px;
	bottom: -20px;
	max-width: none;
	z-index: 5;
	transform: scale(0);
	-webkit-animation: coins-show-data-v-be806286 .6s linear forwards;
	animation: coins-show-data-v-be806286 .6s linear forwards
}

@media(max-width:767px) {
	.wheel-win__coins {
		top: 50%;
		left: 50%;
		right: 0;
		bottom: 0;
		padding-bottom: 56px;
		transform: translate(-50%, -50%) scale(0);
		-webkit-animation: coins-show-mobile-data-v-be806286 .6s linear forwards;
		animation: coins-show-mobile-data-v-be806286 .6s linear forwards
	}
}

.wheel-win__coins-fun {
	width: 100%;
	object-fit: contain;
	z-index: 5;
	-webkit-animation: coins-show-data-v-be806286 .6s linear forwards;
	animation: coins-show-data-v-be806286 .6s linear forwards
}

@media(max-width:767px) {
	.wheel-win__coins-fun {
		position: static;
		width: 100%;
	}
}

.wheel-win__body {
	opacity: 0;
	-webkit-animation: showWin-data-v-be806286 1s linear forwards;
	animation: showWin-data-v-be806286 1s linear forwards
}

.wheel-win__amount-wrap,
.wheel-win__body {
	position: relative
}

.wheel-win__label-img {
	margin-bottom: 10px
}

@media(max-width:767px) {
	.wheel-win__label-img {
		margin-bottom: 15px
	}
}

.wheel-win__amount-block {
	position: absolute;
	bottom: 30px;
	left: 50%;
	display: flex;
	align-items: center;
	transform: translate(-50%)
}

.wheel-win__amount-block img {
	width: 30px;
	height: 30px
}

@media(max-width:767px) {
	.wheel-win__amount-block img {
		width: 24px;
		height: 24px
	}
}

.wheel-win__amount-sum {
	font-weight: 700;
	font-size: 22px;
	line-height: 32px;
	text-shadow: 0 9.5429px 25.4477px rgba(20, 26, 41, .32);
	margin: 0 0 0 7px
}

@media(max-width:767px) {
	.wheel-win__amount-sum {
		font-size: 18px
	}
}

.wheel-win__currency-name {
	font-weight: 700;
	font-size: 22px;
	line-height: 32px;
	margin-left: 4px;
	margin-bottom: 0;
	text-transform: capitalize
}

@media(max-width:767px) {
	.wheel-win__currency-name {
		font-size: 18px
	}
}

.wheel-win__desc {
	font-weight: 500;
	font-size: 16px;
	line-height: 24px;
	max-width: 300px;
	text-align: center;
	margin: 0 auto 16px
}

.wheel-win__desc--orange {
	color: #ffb636
}

.wheel-win__btn-block {
	margin-bottom: 57px;
	margin-left: auto;
	margin-right: auto;
	max-width: 150px;
}

.wheel-win__btn-block .button_fullwidth {
	margin-bottom: 20px;
}

@media(max-width:767px) {
	.wheel-win__btn-block {
		margin-bottom: 40px
	}
}

.wheel-win__btn-block button {
	position: relative;
	overflow: hidden;
	height: 44px;
	border: 0
}

.wheel-win__btn-block button:before {
	left: 20px;
	width: 20px
}

.wheel-win__btn-block button:after,
.wheel-win__btn-block button:before {
	content: "";
	position: absolute;
	top: 50%;
	height: 90px;
	background: #fff;
	opacity: .4;
	filter: blur(5px);
	transform: translate(-150px, -50%) rotate(45deg);
	-webkit-animation: highlight-btn-data-v-be806286 3s linear infinite;
	animation: highlight-btn-data-v-be806286 3s linear infinite
}

.wheel-win__btn-block button:after {
	left: 80px;
	width: 30px
}

@-webkit-keyframes showWin-data-v-be806286 {
	0% {
		opacity: 0
	}

	10% {
		opacity: 0
	}

	to {
		opacity: 1
	}
}

@keyframes showWin-data-v-be806286 {
	0% {
		opacity: 0
	}

	10% {
		opacity: 0
	}

	to {
		opacity: 1
	}
}

@-webkit-keyframes coins-show-data-v-be806286 {
	0% {
		transform: scale(0)
	}

	to {
		transform: scale(1)
	}
}

@keyframes coins-show-data-v-be806286 {
	0% {
		transform: scale(0)
	}

	to {
		transform: scale(1)
	}
}

@-webkit-keyframes coins-show-mobile-data-v-be806286 {
	0% {
		transform: translate(-50%, -50%) scale(0)
	}

	to {
		transform: translate(-50%, -50%) scale(1)
	}
}

@keyframes coins-show-mobile-data-v-be806286 {
	0% {
		transform: translate(-50%, -50%) scale(0)
	}

	to {
		transform: translate(-50%, -50%) scale(1)
	}
}

@-webkit-keyframes highlight-btn-data-v-be806286 {

	0%,
	80% {
		transform: translate(-150px, -50%) rotate(45deg)
	}

	to {
		transform: translate(200px, -50%) rotate(45deg)
	}
}

@keyframes highlight-btn-data-v-be806286 {

	0%,
	80% {
		transform: translate(-150px, -50%) rotate(45deg)
	}

	to {
		transform: translate(200px, -50%) rotate(45deg)
	}
}

.wheel-history {
	position: absolute;
	left:0;
	right: 0;
	top: 0;
	bottom: 0;
	z-index: 34;
	border-radius: 20px;
	overflow: hidden;
	pointer-events: none
}

.wheel-history .user__name {
	font-size: 12px
}

.wheel-history--show {
	pointer-events: all
}

.wheel-history--show .wheel-history__content {
	transition: all 1s;
	transform: translateX(0)
}

@media(max-width:767px) {
	.wheel-history {
		left: 0
	}
}

.wheel-history__content {
	position: absolute;
	right: 0;
	top: 0;
	width: 100%;
	max-width: 100%;
	height: 100%;
	background: #161F2C;
	border-radius: 10px;
	padding: 32px 32px 0;
	transition: all 1s;
	transform: translateX(100%)
}

@media(max-width:767px) {
	.wheel-history__content {
		padding: 32px 24px 0
	}
}

.wheel-history__head {
	display: flex;
	align-items: center;
	margin-bottom: 28px
}

.wheel-history__btn-close {
	width: 38px;
	height: 38px;
	border: none;
	font-weight: 500;
	font-size: 38px;
	line-height: 38px;
	color: #8e939e;
	background-color: transparent;
	padding: 0;
	position: absolute;
	top: 0;
	right: 0;
	outline: none;
	z-index: 10;
	transition: color .1s ease-in-out
}

.wheel-history__btn-close:hover {
	color: #fff;
	color: var(--color_white)
}

.wheel-history .wheel-history__btn-back {
	width: 32px;
	height: 32px;
	padding: 5px;
	margin-right: 8px
}

.wheel-history__title {
	margin: 0;
	font-weight: 600;
	font-size: 18px;
	line-height: 24px
}

.wheel-history__tabs {
	display: flex;
	align-items: center;
	margin-bottom: 16px
}

.wheel-history__tab {
	font-weight: 600;
	font-size: 14px;
	line-height: 18px;
	color: #8e939e;
	margin-right: 14px;
	cursor: pointer;
	padding: 7px 10px
}

.wheel-history__tab--active {
	color: #fff;
	background: #9663d1;
	border-radius: 10px
}

.wheel-history__table {
	width: 100%
}

.wheel-history__row {
	display: flex;
	align-items: center;
	justify-content: space-between;
	border-bottom: 1px solid #1c2532
}

.wheel-history__row:last-child {
	border: none
}

.wheel-history__row--recent .wheel-history__col:first-child {
	max-width: 28%
}

.wheel-history__row--recent .wheel-history__col:nth-child(2) {
	max-width: 40%
}

.wheel-history__row--recent .wheel-history__col:nth-child(3) {
	max-width: 32%
}

.wheel-history__col {
	display: flex;
	align-items: center;
	max-width: 25%;
	width: 100%;
	height: 40px
}

.wheel-history__col:first-child {
	max-width: 40%
}

.wheel-history__col:nth-child(2) {
	max-width: 35%
}

@media(max-width:767px) {
	.wheel-history__col:first-child {
		max-width: 28%
	}

	.wheel-history__col:nth-child(2) {
		max-width: 40%
	}

	.wheel-history__col:last-child {
		max-width: 32%
	}
}

.wheel-history__col--time {
	font-weight: 600;
	font-size: 12px;
	line-height: 16px;
	color: #8e939e
}

.wheel-history__col--head {
	font-weight: 600;
	font-size: 11px;
	line-height: 16px;
	color: #55657e
}

.fury-wheel-modal {
	position: relative;
	transition: all 1s;
	overflow: visible !important;
}


.fury-wheel-modal--play {
	margin-left: 0
}

.fury-wheel-modal:before {
	display: none
}

.fury-wheel-modal .modal__container {
	padding: 0;
	height: 100%
}

@media(max-width:767px) {
	.fury-wheel-modal {
		margin-left: 0;
		overflow: visible !important
	}
}

.modal__btn-close {
	margin-left: auto;
	margin-right: auto;
	margin-top: 12px;
	width: 38px;
	height: 38px;
	border: none;
	font-weight: 500;
	font-size: 38px;
	line-height: 38px;
	background-color: transparent;
	color: #8e939e;
	padding: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	outline: none;
	z-index: 10;
	transition: color .1s ease-in-out
}

.modal__btn-close:hover,
.modal__container {
	color: #fff;
}

.modal__container {
	position: relative;
	z-index: 2;
	min-height: 62px;
	padding: 20px 24px
}

.button_grey1.button {
	color: #8e939e;
}

.user__wrap {
	display: flex;
	flex-direction: column;
}

.user--clickable {
	cursor: pointer;
}

.user {
	display: flex;
	align-items: center;
}

.user__additional-info,
.user__name {
	margin-left: 8px;
}

.fury-less-time {
	height: 36px;
	border-radius: 14px;
	display: flex;
	align-items: center;
	justify-content: center;
	line-height: 1;
	margin-bottom: 10px;
}
.progress{
	margin: 0 auto;
	margin-top: 20px;
}
.fury-wheel__head{
	width: 440px;
	margin-left: auto;
	margin-right: auto;
	animation: wheelHead 1s ease-in 1 alternate;
}
.progress .el-progress-bar__outer{
	box-shadow: inset 0px 0px 6px 0px #532A00;
}
.progress .el-progress-bar__inner{
	background: linear-gradient( 90deg, #2BAF98 0%, #029572 100%);
	box-shadow: inset 0px 0px 5px 0px rgba(0,47,33,0.75);
	border-radius: 11px;
}


@media(max-width:767px) {
	.fury-wheel__racoon-img {
		width: 268px;
		margin-top: 0
	}
}
.fury-wheel__wheel-foot{
	position: absolute;
	width: 620px;
	bottom: -88px;
	left: -108px;
}
.fury-wheel__wheel-cions{
	position: absolute;
	left: -70px;
	top: 0;
	width: 552px;
	height: 452px;
}
.fury-icon{
	position: absolute;
}
.fury-icon-1{
	width: 150px;
	left: -60px;
	top: -77px;
	animation: wheeldownup 3s linear infinite alternate;
}
.fury-icon-2{
	width: 100px;
	left: 30px;
	top: 20px;
}
.fury-icon-3{
	width: 172px;
    left: -8px;
    top: 114px;
	animation: wheeldownup3 3s linear infinite alternate;
}
.fury-icon-4{
	width: 160px;
	right: 0;
	top: 0;
	animation: wheeldownup4 2.4s linear infinite alternate;
}
.fury-icon-5{
	width: 175px;
	right: 6px;
	bottom: 80px;
	animation: wheeldownup5 2.2s linear infinite alternate;
}
.fury-icon-6{
	width: 36px;
    right: -20px;
    top: 36%;
	animation: wheeldownup6 2.9s linear infinite alternate;
}

@keyframes wheeldownup {
	0% {
		opacity: .8;
		transform: translate(0, -50px) rotate(-10deg)
	}

	80% {
		opacity: 1
	}

	to {
		opacity: 1;
		transform: translate(0, 0) rotate(0)
	}
}
@keyframes wheeldownup2 {
	0% {
		opacity: .8;
		transform: translate(0, 0) rotate(10deg)
	}

	80% {
		opacity: 1
	}

	to {
		opacity: 1;
		transform: translate(-10px, 0) rotate(0)
	}
}
@keyframes wheeldownup3 {
	0% {
		opacity: .8;
		transform: translate(0, 0) rotate(-5deg)
	}

	80% {
		opacity: 1
	}

	to {
		opacity: 1;
		transform: translate(-2px, 0) rotate(0)
	}
}
@keyframes wheeldownup4 {
	0% {
		opacity: .8;
		transform: translate(0, -30px) rotate(10deg)
	}

	80% {
		opacity: 1
	}

	to {
		opacity: 1;
		transform: translate(0, 0) rotate(0)
	}
}
@keyframes wheeldownup5 {
	0% {
		opacity: .8;
		transform: translate(0, -5px) rotate(-5deg)
	}

	80% {
		opacity: 1
	}

	to {
		opacity: 1;
		transform: translate(0, 0) rotate(0)
	}
}
@keyframes wheeldownup6 {
	0% {
		opacity: .8;
		transform: translate(0, 0) rotate(-5deg)
	}

	80% {
		opacity: 1
	}

	to {
		opacity: 1;
		transform: translate(3px, 0) rotate(0)
	}
}
@media (max-width: 767px) {
	.fury-wheel__wheel-box{
		width: 280px;
		height: 280px;
	}
	.fury-wheel__head{
		text-align: center;
		width: 100%;
		padding: 0 20px;
	}
	.fury-wheel__head img{
		width: 200px;
	}
	.fury-wheel__wheel-foot{
		width: auto;
		bottom: -40px;
		left: -30px;
		right: -30px;
	}
	.fury-wheel__wheel-cions{
		width: 316px;
		height: 316px;
		left: 0;
	}
	.fury-wheel__btn span{
		font-size: 14px;
	}

	.fury-icon-1{
		width: 75px;
		left: -40px;
		top: -7px;
	}
	.fury-icon-2{
		width: 50px;
		left: -10px;
		top: 10px;
	}
	.fury-icon-3{
        width: 80px;
        left: -35px;
        top: 127px;
	}
	.fury-icon-4{
		width: 80px;
		right: 0;
		top: 0;
	}
	.fury-icon-5{
		width: 80px;
		right: 3px;
		bottom: 60px;
	}
	.fury-icon-6{
		width: 18px;
		right: -10px;
		top: 36%;
	}
	.modal__btn-close{
		width: 30px;
		height: 30px;
		margin-top:20px;
	}

}


@keyframes wheelHead {
	0% {
		opacity: .8;
		transform: translate(0, -30px)
	}
	to {
		opacity: 1;
		transform: translate(0, 0)
	}
}
@keyframes wheelBody {
	0% {
		opacity: .8;
		transform: translate(0, -30px)
	}
	to {
		opacity: 1;
		transform: translate(0, 0)
	}
}
@keyframes wheelScroll {
    0% {
        opacity: 0.8;
        transform: translate(-100px, -50%) rotateZ(45deg); /* 去掉引号 */
    }
    100% { /* 使用100%代替to，更加清晰 */
        opacity: 1;
        transform: translate(-50%, -50%) rotateZ(0);
    }
}
@keyframes wheelFooter {
	0% {
		opacity: .8;
		transform: translate(0, 30px)
	}
	to {
		opacity: 1;
		transform: translate(0, 0)
	}
}







</style>
